import React from 'react'
import { Link } from 'react-router-dom'

import SitDownAstronaut from '../../assets/images/sit-down-astronaut.png'
import RightArrowPUrple from '../../assets/images/right-arrow-purple.svg'

import './pageNotFound.scss'

const PageNotFound = _ => {
  return (
    <main className="main-page-not-found">
      <div className="main-page-not-found__content-container">
        <div className="main-page-not-found__main-text-container">
          <h2 className="main-page-not-found__h2">Ups</h2>
          <h1 className="main-page-not-found__h1">404</h1>
          <figure className="main-page-not-found__image-container">
            <img src={SitDownAstronaut} alt="astronaut" />
          </figure>
        </div>

        <div className="main-page-not-found__texts-container">
          <h3 className="main-page-not-found__h3">
            La página que intentas solicitar no está en el servidor (Error 404)
          </h3>
          <p className="main-page-not-found__p">Vuelve hacia atrás o visita de nuevo nuestra página principal</p>
          <Link className="main-page-not-found__go-home" to="/home-page">
            Ir a la página principal
            <img className="main-page-not-found__arrow" src={RightArrowPUrple} alt="purple-arrow" />
          </Link>
        </div>
      </div>
    </main>
  )
}

export default PageNotFound