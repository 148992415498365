import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

const MatchReferenceCode = _ => {
  const checkReferenceCode = referenceCode => {
    if (useParams().id === referenceCode){
      return useParams().id
    } else {
      return "error-matched"
    }
  }
  
  const [id, setId] = useState(useParams().id ? checkReferenceCode("moniquita") : "")
  const navigate = useNavigate()
  
  useEffect(_ => {
    if (id === "error-matched") {
      navigate("/error-page")
    }
  }, [])

  return id
}

export default MatchReferenceCode